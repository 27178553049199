.footer-wrapper {
  background-color: #05889C;
  padding: 35px 50px 50px 50px;
}

.banner-provider {
  background-color: #05889C;
  display: grid;
  justify-content: end;
  padding: 0 20px 20px 0;
  font-weight: 100;
}

.footer-title {
  font-family: 'Alice' !important;
  justify-content: center;
  display: grid;
  font-size: 24px;
  margin: 20px 0 10px;
}

.footer-subtitle {
  font-weight: 100;
  font-size: 14px;
  color: #2e2e2e;
  font-style: italic;
  display: grid;
  justify-content: end;
  margin-bottom: 20px;
}

.maapi-wrapper {
  // display: grid;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  // margin-right: 130px;
}

.maapi-img-footer {
  // width: 100px;
  height: 60px;
  margin-right: 20px;
}

#map-image {
  margin-top: 10px;
  width: auto;
  height: 100px;
}

.google-map {
  margin-left: 20px;
}

.details-map {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-icon {
  width: 20px;
  filter: invert(0%) sepia(99%) saturate(7477%) hue-rotate(271deg) brightness(0) contrast(100%);
  padding-right: 8px;
}

.business-details {
  padding-bottom: 16px;
}

.footer-logo {
  display: none;
}
.linkedIn {
  width: 40px;
  margin: 20px 0;
}

@media only screen and (min-width: 760px) {
  .footer-wrapper {
    padding: 30px;
  }


  .footer-logo {
    display: grid;
    height: 70px;
    width: 70px;
    border-radius: 50%;
  }

  .footer {
    display: flex;
  }

  .footer-title {
    padding-left: 20px;
  }

  .footer-subtitle {
    font-size: 18px;
    width: 600px;
    margin: 0 0 25px 80px;
  }

  .details-flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
  }

  .maapi-wrapper {
    justify-content: start;
    margin: 20px 0 20px 80px;
  }

  .maapi-img-footer {
    height: 70px;
  }

  #map-image {
    width: auto;
    height: 200px;
  }

  .linkedIn {
    width: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .footer-wrapper {
    display: grid;
    padding: 70px 150px;
    grid-template-columns: 1fr 1fr;
  }

  .footer-subtitle {
    width: 350px;
    margin: 0 20px 25px 80px;
  }

  .google-map {
    display: flex;
    justify-content: space-around;

  }

}