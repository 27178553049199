.quote {
  margin: 30px 50px;
  p {
    font-family: 'Bentham-Regular' !important;
  }
}

.quote-box {
  padding: 17px 30px;
  font-size: 12px;
  justify-items: center;
  display: inline-grid;
  text-align: center;
}

.individual {
  background: rgb(5,136,156);
  background: linear-gradient(123deg, rgba(5,136,156,0.6587009803921569) 14%, rgba(6,178,115,0.6166841736694677) 84%);
}
.organisation {
  background: rgb(5,136,156);
  background: linear-gradient(21deg, rgba(5,136,156,0.6587009803921569) 14%, rgba(6,73,187,0.6082808123249299) 94%);
}

.quote-author {
  margin-top: 10px;
  font-size: 8px;
}

@media only screen and (min-width: 760px) {
  .quote-box {
    padding: 30px 45px;
  }

  .quote {
    margin: 30px 100px;
    p {
      font-size: 18px;
    }
  }

  .quote-author {
    font-size: 14px !important;
  }

}

@media only screen and (min-width: 1200px) {
  .quote {
    margin: 70px 260px;
    p {
      font-size: 22px;
    }
  }

  .quote-author {
    font-size: 16px !important;
  }
}