.main-header {
  text-align: center;
  margin: 20px;
  justify-content: center;
  margin-bottom: 30px;
}

.logo {
  display: grid;
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.main-title {
  font-size: 20px;
}

.about-me-body {
  margin-bottom: 50px;
}

.about-me-img-sub {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
  justify-content: center;
}

.about-me-subtitle {
  float: left;
  margin-left: 20px;
  background-color: rgba(39, 199, 92, 0.4);
  padding: 15px;
  color: #202020;
  text-align: center;

  h5 {
    padding-top: 5px;
    font-weight: 100;
  }
}


.about-me-img {
  float: right;
  background-color: gray;
  width: auto;
  height: 100px;
  border-radius: 100%;
  // border : 1px solid black;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.6);
}

.about-me-content {
  font-size: 12px;
  padding: 0 20px 10px 20px;
}



.about-me-paragraph {
  padding-top: 20px;
}

.read-more {
  font-size: 12px;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
}

.read-more-container {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (min-width: 760px) {
  .logo {
    width: 100px;
    height: 100px;
  }

  .main-title {
    font-size: 30px !important;
  }

  .about-me-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 40px;
  }

  .about-me-img-sub {
    order: 2;
    display: grid;
    justify-items: center;
  }

  .about-me-img {
    margin-bottom: 18px;
    height: 130px;
    width: auto;
  }

  .about-me-subtitle {
    font-size: 17px;
    margin-left: 0px;
    width: 110px;
  }

  .about-me-content {
    order: 1;
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) {
  .main-title {
    font-size: 42px !important;
  }

  .main-header {
    margin-top: 50px;
  }

  .about-me-content {
    font-size: 22px;
  }

  .about-me-img {
    height: 160px;

  }

  .about-me-img-sub {
    margin-left: 20px;
  }
}