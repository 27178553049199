@font-face {
  font-family: 'Alice';
  src: local('Alice'), url(./fonts/Alice.ttf) format('truetype');
}

@font-face {
  font-family: 'HindVadodara-Regular';
  src: local('HindVadodara-Regular'), url(./fonts/HindVadodara-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AtkinsonHyperlegible-Regular';
  src: local('AtkinsonHyperlegible-Regular'), url(./fonts/AtkinsonHyperlegible-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Belgrano-Regular';
  src: local('Belgrano-Regular'), url(./fonts/Belgrano-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Bentham-Regular';
  src: local('Bentham-Regular'), url(./fonts/Bentham-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Commissioner';
  src: local('Commissioner'), url(./fonts/Commissioner.ttf) format('truetype');
}

@font-face {
  font-family: 'MerriweatherSans';
  src: local('MerriweatherSans'), url(./fonts/MerriweatherSans.ttf) format('truetype');
}