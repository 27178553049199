
.client-type {
  display: inline-grid;
  justify-items: center;
  font-size: 12px;
  margin-right: 20px;
}

@media only screen and (min-width: 760px) {
  .client-type {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) {
  .client-type {
    font-size: 20px;
  }
}