.user_input {
  margin: 10px;
  width: 250px;
}

.input_title {
  font-size: 18px;
  padding-bottom: 3px;
}

.form-error {
  font-size: 15px;
  margin: 0 0 4px 13px;
  color: rgb(255, 0, 0);
  font-weight: 800;
  display: none;
}

input[focused='false'] {
  margin-bottom: 10px;
}

input:valid[focused='true'] {
  margin-bottom: 10px;
}

input:invalid[focused='true'] {
  // border: 1px solid red;
}

input:invalid[focused='true'] ~ .form-error {
  display: block;
  margin: 2px 0 10px 0;
}

textarea:invalid[focused='true'] ~ .form-error {
  display: block;
}

@media only screen and (min-width: 760px) {
  .input_title {
    font-size: 20px;
  }
  .user_input {
    width: 300px;
  }
}

@media only screen and (min-width: 1200px) {
  .input_title {
    font-size: 22px;
  }
}