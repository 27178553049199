.header-wrapper {
  justify-content: space-between;
  margin: 20px;
}

.header {
  cursor: pointer;
}

.home-icon-wrapper {
  cursor: pointer;
  img {
    margin-right: 10px;
  }
}

.home-icon-wrapper:hover {
  text-decoration: underline;
}

.contact-bar {
  width: 100%;
  margin: 0;
}

@media only screen and (min-width: 760px) {
  .header-wrapper {
    margin: 35px 80px;
  }
  .home-icon-wrapper {
    img {
      width: 32px !important;
    }
    p {
      font-size: 20px;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header-wrapper {
    margin: 35px 180px;
  }
  .home-icon-wrapper {
    p {
      font-size: 22px;
    }
  }
}