.services {
  font-size: 12px;
  margin: 10px 70px 10px 70px;
}

.button-container{
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.service-button {
  width: 200px;
  height: 30px;
  border: 0;
  border-radius: 10px;
  color:#ffffff;
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  letter-spacing: 5px;
  box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
  transition: box-shadow .2s ease-in-out;
  transition: background-color 0.4s ease;
}

.service-button:active {
  box-shadow: inset 2px 2px 2px rgba(0, 0, 0, .8), inset -1px -1px 1px rgba(255, 255, 255, .4);
}

.individuals {
  background-color: #069BB2;
}

.individuals:hover {
  background-color: #0649BB;
  // animation: bubble 0.4s ease-out;
}

@keyframes bubble {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.organisations {
  background-color: #0649BB;
}
.organisations:hover {
  background-color: #069BB2;
}

.service-img {
  // height: 150px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: -5px;
}

.to-contact {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (min-width: 760px) {
  .services {
    font-size: 18px;
    font-weight: 600;
    display: grid;
    justify-content: center;
    line-height: 30px;
    margin: 30px 0;
  }
  .service-button {
    height: 50px;
    font-size: 20px;
  }
  .service-img {
    // height: 250px;
  }
}

@media only screen and (min-width: 1200px) {
  .services {
    font-size: 22px;
  }
  .service-button {
    width: 350px;
    font-size: 20px;
  }
  .service-img {
    // height: 350px;
  }
}