.title {
  margin-left: 20px;
  font-family: 'Alice', serif !important;
}

@media only screen and (min-width: 760px) {
  .title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1200px) {
  .title {
    font-size: 28px;
  }
}