* {
  margin: 0;
  padding: 0;
  font-family: 'HindVadodara-Regular';
  // background-color: rgba(161, 161, 161, 0.2);
}

hr {
  background-color: rgb(152, 152, 152);
  margin: 4px 0 12px 18px;
  width: 80%;
  height: 2px;
  border: none;
}

.flex {
  display: flex;
  align-items: center;
}

.icon {
  filter: invert(0%) sepia(99%) saturate(7477%) hue-rotate(271deg) brightness(0) contrast(100%);
  width: 20px;
}

.content {
  font-size: 12px;
  margin: 0 20px 10px 20px;
  a {
    font-weight: 900;
  }
}

input[type="text"] {
  font-size:16px; 
  height: 40px;
  width: inherit;
  padding-left: 5px;
  border: none;
  border-bottom: 1.5px solid #D9D9D9
}

textarea {
  font-size:16px; 
  height: 120px;
  width: inherit;
  padding: 8px;
  border: none;
  border-bottom: 1.5px solid #D9D9D9;
}

.mandatory {
  color: red;
  margin-left: 5px;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@media only screen and (min-width: 760px) {
  .main-container {
    margin: 0px 40px;
  }
  .icon {
    width: 40px !important;
  }

  .content {
    font-size: 18px;
    margin: 20px 50px;
  }

  input[type="text"] {
    font-size:18px; 
  }

  textarea {
    font-size:18px; 
  }
}

@media only screen and (min-width: 1200px) {
  .main-container {
    margin: 0px 180px;
  }

  .content {
    font-size: 22px;
    margin: 50px 50px;
  }

  input[type="text"] {
    font-size:20px; 
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 190px;
    font-size:20px; 
  }
}