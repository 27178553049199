.form-wrapper {
  padding: 50px 20px;
  border: 1px solid #8f8f8f;
  margin: 30px;
  border-radius: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: grid;
  justify-content: center;
  min-width: 300px;
}

.form {
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
}

.form-title {
  margin: 30px 0 0 20px;
  color: rgb(28, 28, 28);
}

.form-subtitle {
  margin: 10px 0 0 20px;
  font-weight: 300;
}

.form-user-input {
  display: grid;
  justify-content: center;
}

.btn-container {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.cancel-btn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 16px;
  margin-right: 20px;
}

.cancel-btn:hover {
  text-decoration: underline;
}

.submit-btn {
  cursor: pointer;
  font-size: 16px;
  border: none;
  background-color: #4CA3B5;
  padding: 12px 24px;
  border-radius: 30px;
  color: #ffffff;
  transition: background-color 0.4s ease;
  button {
    border: none;
    // background-color: #4CA3B5;
  }
}

.submit-btn:hover {
  color: #ffffff;
  background-color: #0aaecf;
}

.spinner {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.spinner::before , .spinner::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.spinner::after{
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #4CA3B5;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}



.submit-btn:active {
  letter-spacing: 2px;
}

.input-required {
  margin-left: 5px;
  color: rgb(255, 0, 0);
  font-weight: 900;
  font-size: 20px;
}

.onclic {
  width: 40px;
  border-color:gray;
  border-width:3px;
  font-size:0;
  border-left-color:green;
  animation: rotating 2s 0.25s linear infinite;

  &:after {
    content:"";
  }
  &:hover {
    color:green;
    background: white;
  }
}
.validate {
  font-size:13px;
  color: white;
  background-color: green;
  &:after {
    font-family:'FontAwesome';
    content:"\f00c";
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.message {
  text-align: center;
  margin-top: 20px;
}

.error {
  color: rgb(255, 0, 0);
  font-weight: 700;
}

@media only screen and (min-width: 760px) {
  .form-title {
    font-size: 22px;
  }

  .form-subtitle {
    font-size: 18px;
  }

  .cancel-btn {
    font-size: 18px;
  }

  .submit-btn {
    button{
      font-size: 18px;
    }
    padding: 12px 30px;
  }

  .input-required {
    font-size: 26px;
  }

}

@media only screen and (min-width: 950px) {
  .form-wrapper {
    margin: 50px 150px;
  }
}

@media only screen and (min-width: 1200px) {
  .form-title {
    font-size: 26px;
  }

  .form-subtitle {
    font-size: 22px;
  }

  .submit-btn {
    padding: 14px 32px;
    button {
      font-size: 20px;
    }
  }

  .cancel-btn {
    font-size: 20px;
  }

  .form-wrapper {
    margin: 50px 10px 80px 10px;
    padding: 80px ;
    form {
      display: flex;
    }
    // display: flex;
  }

  .message-container {
    margin-top: 29px;
    width: inherit;
  }

  .form-user-input {
    display: flex;
  }

  .name-email-phone-container {
    width: inherit;
    margin-right: 100px;
  }

  .message {
    text-align: end;
    font-size: 18px;
  }




}